import React, { useState, useEffect, RefObject } from "react";

const useOpaqueNavbar = (bgImageRef: RefObject<any>) => {
  const [navbarOpaque, setNavbarOpaque] = useState(false);
  useEffect(() => {
    const threshold = 0;
    const bgImageHeight = bgImageRef.current?.clientHeight || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setNavbarOpaque(scrollY > bgImageHeight - 92);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [navbarOpaque]);
  return navbarOpaque;
};

export default useOpaqueNavbar;
