import React, { useRef, Suspense } from "react";
import "twin.macro";
import { graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import { ArrowDownIcon } from "@heroicons/react/solid";
import { Navbar } from "../components";
import { useOpaqueNavbar } from "../utils";

const AboutUs = React.lazy(
  () => import("../components/pages/indexPage/AboutUs")
);
const HearingAidBrands = React.lazy(
  () => import("../components/pages/indexPage/HearingAidBrands")
);
const OnlineHearingTest = React.lazy(
  () => import("../components/pages/indexPage/OnlineHearingTest")
);
const TheTeam = React.lazy(
  () => import("../components/pages/indexPage/TheTeam")
);
const LocationBlock = React.lazy(
  () => import("../components/common/LocationBlock")
);

const IndexPage = ({ data: { allStrapiBio, allStrapiBranch } }) => {
  const bgImageRef = useRef(null);
  const navbarOpaque = useOpaqueNavbar(bgImageRef);

  return (
    <>
      <SEO title="Home" />
      <Navbar opaque={navbarOpaque} />
      <section aria-label="Intro">
        <div tw="grid w-full" ref={bgImageRef}>
          <div tw="absolute top-0 w-full h-screen transition-opacity duration-1000 ease-in-out bg-black opacity-70" />
          <StaticImage
            src="../images/samuel-dixon-119943-unsplash.jpg"
            alt=""
            layout="fullWidth"
            placeholder="blurred"
            loading="eager"
            tw="h-screen grid-area[1/1]"
            quality={90}
          />
          <div tw="grid grid-area[1/1] place-items-center">
            <div tw="z-20 px-4 py-5 text-center text-white transition-opacity duration-500 ease-in-out">
              <h1 tw="mb-10 uppercase font-display text-[40px] letter-spacing[2.5px] md:(text-[50px] letter-spacing[4px]) xl:text-6xl text-shadow[0px 0px 20px rgb(0 0 / 55%)] line-height[1.25]">
                Independent Hearing
              </h1>
              <h2 tw="font-light uppercase letter-spacing[2.5px] line-height[1] md:text-xl xl:text-2xl">
                {allStrapiBranch.totalCount} locations across England
              </h2>
            </div>
            <ArrowDownIcon
              tw="absolute w-8 h-8 text-white -translate-x-1/2 cursor-pointer bottom-8 left-1/2 animate-pulse-slow"
              onClick={() => scrollTo("#about-us")}
            />
          </div>
        </div>
      </section>
      <main>
        <Suspense fallback={null}>
          <AboutUs />
        </Suspense>
        <Suspense fallback={null}>
          <OnlineHearingTest />
        </Suspense>
        <Suspense fallback={null}>
          <TheTeam team={allStrapiBio.edges.map((a) => a.node)} />
        </Suspense>
        <Suspense fallback={null}>
          <HearingAidBrands />
        </Suspense>
        <Suspense fallback={null}>
          <LocationBlock />
        </Suspense>
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    allStrapiBio(limit: 2, sort: { order: ASC, fields: ordering }) {
      edges {
        node {
          name
          title
          ordering
          qualifications
          biography {
            data {
              biography
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250)
              }
            }
          }
        }
      }
    }
    allStrapiBranch {
      totalCount
    }
  }
`;
